
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

interface Client {
  clientId: number | null;
  name: string;
  numOfEmployees: number;
  primaryContactName: string;
  primaryContactEmail: string;
  licensedDistributorAssociateAspNetUserId: string;
  active: boolean;
}

export default defineComponent({
  name: "incline-editClient",
  props: ["clientid"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props) {
    const store = useStore();
    const isDataReady = ref(false);
    const currentUser = store.getters.currentUser;
    const clientInfo = computed(() => {
      return store.getters.getClientInfo(Number(props.clientid));
    });

    const submitProfileButton = ref<HTMLElement | null>(null);

    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const clientDetailsValidator = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      numOfEmployees: Yup.number().min(1).required().label("Employees"),
      primaryContactName: Yup.string().required().label("Primary Contact"),
      primaryContactEmail: Yup.string().email().required().label("Email"),
    });

    const clientProfile = ref<Client>({
      clientId: clientInfo.value.clientId,
      name: clientInfo.value.name,
      numOfEmployees: clientInfo.value.numOfEmployees,
      primaryContactName: clientInfo.value.primaryContactName,
      primaryContactEmail: clientInfo.value.primaryContactEmail,
      licensedDistributorAssociateAspNetUserId:
        clientInfo.value.licensedDistributorAssociateAspNetUserId,
      active: clientInfo.value.active,
    });

    const contentExperts = computed(() => {
      return store.getters.getContentExpertDDL;
    });

    //const dispatchGetExpertDDL = () => {
    store
      .dispatch(Actions.GET_CONTENT_EXPERT_DDL, props.clientid)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving content experts.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });
    //};

    const saveProfileChanges = () => {
      if (submitProfileButton.value) {
        // Activate indicator
        submitProfileButton.value?.setAttribute("data-kt-indicator", "on");
        // Send Update
        store
          .dispatch(Actions.UPDATE_CLIENT, clientProfile.value)
          .then(() => {
            Swal.fire({
              text: "Success. The client has been updated.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "There was a problem saving your updates!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitProfileButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settings", ["Client"]);
    });

    watch(clientInfo, () => {
      isDataReady.value = true;
      //dispatchGetExpertDDL;
    });

    return {
      clientInfo,
      currentUser,
      clientProfile,
      isDataReady,
      contentExperts,
      phoneRegExp,
      submitProfileButton,
      //dispatchGetExpertDDL,
      saveProfileChanges,
      clientDetailsValidator,
    };
  },
});

<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Client Details</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Form-->
      <Form
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
        @submit="saveProfileChanges"
        :validation-schema="clientDetailsValidator"
      >
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          <!--begin::Input group-->
          <div class="row mb-6">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6"
              >Name</label
            >
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    type="text"
                    name="name"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    placeholder="Business name"
                    v-model="clientProfile.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="row mb-6">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6"
              ># of Employees</label
            >
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    type="text"
                    name="numOfEmployees"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    placeholder="# of employees"
                    v-model="clientProfile.numOfEmployees"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="numOfEmployees" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="row mb-6">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6"
              >Primary Contact</label
            >
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    type="text"
                    name="primaryContactName"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    placeholder="Primary contact name"
                    v-model="clientProfile.primaryContactName"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="primaryContactName" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="row mb-6">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6"
              >Primary Contact Email</label
            >
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    type="text"
                    name="primaryContactEmail"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    placeholder="Primary contact email"
                    v-model="clientProfile.primaryContactEmail"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="primaryContactEmail" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div
            class="row mb-6"
            v-if="
              currentUser.role === 'SuperAdmin' ||
              currentUser.role === 'LicensedDistributor'
            "
          >
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6"
              >Primary Content Expert</label
            >
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <!--begin: Licensed Distributor Associate-->
                  <Field
                    name="licensedDistributorAssociate"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    as="select"
                    v-model="
                      clientProfile.licensedDistributorAssociateAspNetUserId
                    "
                  >
                    <option value="">None Selected</option>
                    <option
                      v-for="item in contentExperts"
                      :key="item.associateAspNetUserId"
                      :label="item.name"
                      :value="item.associateAspNetUserId"
                    >
                      {{ item.name }}
                    </option>
                  </Field>
                  <!--end:Licensed Distributor Associate-->
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
        </div>
        <!--end::Card body-->

        <!--begin::Actions-->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button
            type="reset"
            class="btn btn-white btn-active-light-primary me-2"
          >
            Discard
          </button>

          <button
            id="kt_account_profile_details_submit"
            ref="submitProfileButton"
            type="submit"
            class="btn btn-primary"
          >
            <span class="indicator-label"> Save Changes </span>
            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Actions-->
      </Form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

interface Client {
  clientId: number | null;
  name: string;
  numOfEmployees: number;
  primaryContactName: string;
  primaryContactEmail: string;
  licensedDistributorAssociateAspNetUserId: string;
  active: boolean;
}

export default defineComponent({
  name: "incline-editClient",
  props: ["clientid"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props) {
    const store = useStore();
    const isDataReady = ref(false);
    const currentUser = store.getters.currentUser;
    const clientInfo = computed(() => {
      return store.getters.getClientInfo(Number(props.clientid));
    });

    const submitProfileButton = ref<HTMLElement | null>(null);

    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const clientDetailsValidator = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      numOfEmployees: Yup.number().min(1).required().label("Employees"),
      primaryContactName: Yup.string().required().label("Primary Contact"),
      primaryContactEmail: Yup.string().email().required().label("Email"),
    });

    const clientProfile = ref<Client>({
      clientId: clientInfo.value.clientId,
      name: clientInfo.value.name,
      numOfEmployees: clientInfo.value.numOfEmployees,
      primaryContactName: clientInfo.value.primaryContactName,
      primaryContactEmail: clientInfo.value.primaryContactEmail,
      licensedDistributorAssociateAspNetUserId:
        clientInfo.value.licensedDistributorAssociateAspNetUserId,
      active: clientInfo.value.active,
    });

    const contentExperts = computed(() => {
      return store.getters.getContentExpertDDL;
    });

    //const dispatchGetExpertDDL = () => {
    store
      .dispatch(Actions.GET_CONTENT_EXPERT_DDL, props.clientid)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving content experts.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });
    //};

    const saveProfileChanges = () => {
      if (submitProfileButton.value) {
        // Activate indicator
        submitProfileButton.value?.setAttribute("data-kt-indicator", "on");
        // Send Update
        store
          .dispatch(Actions.UPDATE_CLIENT, clientProfile.value)
          .then(() => {
            Swal.fire({
              text: "Success. The client has been updated.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "There was a problem saving your updates!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitProfileButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settings", ["Client"]);
    });

    watch(clientInfo, () => {
      isDataReady.value = true;
      //dispatchGetExpertDDL;
    });

    return {
      clientInfo,
      currentUser,
      clientProfile,
      isDataReady,
      contentExperts,
      phoneRegExp,
      submitProfileButton,
      //dispatchGetExpertDDL,
      saveProfileChanges,
      clientDetailsValidator,
    };
  },
});
</script>
